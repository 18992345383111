const Productdb = [
  {
    id: 1,
    image: "../website all product-06.webp",
    image2: "../product all website.webp",
  },
  {
    id: 2,
    image: "../city samosa only dish 2.webp",
    span1: "Bite",
    span2: "into joy with",
    span3: "City Samosa!",
    Productnu: "Product 1 / 12",
    Productdisc: "Samosa is a popular Indian snack made of a crispy, triangular pastry filled with spiced potatoes, peas, or meat. It's deep-fried and served hot with chutneys, offering a tasty blend of flavors and textures.",
  },
  {
    id: 3,
    image: "../vada pav 1 1.webp",
    span1: "Feel",
    span2: "the spice in every",
    span3: "Vada Pav bite!",
    Productnu: "Product 2 / 12",
    Productdisc: "Vada Pav is a popular Indian street food from Maharashtra. It’s a spicy potato fritter (vada) inside a soft bun (pav), served with chutneys and spices. Simple, flavorful, and loved by many.",
  },
  {
    id: 4,
    image: "../pattice pakoda 1 1.webp",
    span1: "Crispy",
    span2: "outside, spicy inside",
    span3: "Bread Pakoda!",
    Productnu: "Product 3 / 12",
    Productdisc: "Bread Pakoda is a popular Indian snack made by dipping bread slices in spiced gram flour batter and deep-frying until crispy. It’s a tasty, crunchy treat often enjoyed with chutney.",
  },
  {
    id: 5,
    image: "../crispy pattice pakoda-11 (1).webp",
    span1: "Crisp",
    span2: "outside, spicy inside",
    span3: "Crispy Bread Pakoda!",
    Productnu: "Product 4 / 12",
    Productdisc: "Crispy Bread Pakoda is a popular Indian snack made by stuffing bread slices with spiced potato filling, dipping them in chickpea batter, and deep-frying until golden. Crunchy on the outside and soft inside, it’s often served with chutneys for a delicious treat.",
  },
  {
    id: 6,
    image: "../ulta vada pav 1 1.webp",
    span1: "Ulta Vada Pav",
    span2: "a twist of ",
    span3: "flavors!",
    Productnu: "Product 5 / 12",
    Productdisc: "Ulta Vada Pav is a twist on the classic, featuring a soft, upside-down bun with a spicy potato fritter (vada) on top. Topped with chutneys and spices, it offers a delicious blend of flavors.",
  },
  {
    id: 7,
    image: "../crispy vada pav 3 3.webp",
    span1: "Crispy Vada Pav",
    span2: "crunch into ",
    span3: "happiness!",
    Productnu: "Product 6 /  12",
    Productdisc: "Crispy Vada Pav is a popular Indian street food featuring a spiced potato fritter (vada) coated in a crunchy batter, served in a soft bun (pav). Often accompanied by chutneys, it offers a delightful combination of textures and flavors.",
  },
  {
    id: 8,
    image: "../misal.webp",
    span1: "Misal Pav",
    span2: " A Maharashtrian",
    span3: "crunch of flavor !",
    Productnu: "Product 7 /  12",
    Productdisc: "Misal is a spicy Maharashtrian dish made from sprouted lentils in a tangy gravy. Garnished with onions and tomatoes, it's often served with crispy pav (bread rolls) for a flavorful meal or snack.",
  },
  {
    id: 9,
    image: "../KACHORI BANNER.webp",
    span1: "Crispy and Flavorful:",
    span2: "The Famous",
    span3: "Shegaon Kachori",
    Productnu: "Product 8 /  12",
    Productdisc: "Shegaon Kachori is a popular Indian snack from Maharashtra, featuring a flaky, deep-fried pastry filled with a spiced mixture of lentils and herbs. Crispy on the outside and flavorful on the inside, it is often enjoyed with chutneys or yogurt.",
  },
  {
    id: 10,
    image: "../Masala-Chaas.webp",
    span1: "Sip the",
    span2: "Authentic Flavor of",
    span3: "Malsa Chaas",
    Productnu: "Product 9 /  12",
    Productdisc: "Masala Chaas is a refreshing Indian buttermilk drink, spiced with herbs like cumin and mint. Light and tangy, it's often served chilled and is perfect for cooling down on hot days.",
  },
  {
    id: 11,
    image: "../lassi.webp",
    span1: "Lassi:",
    span2: "The Sweet and",
    span3: "Creamy Indian Classic",
    Productnu: "Product 10 /  12",
    Productdisc: "Lassi is a traditional Indian yogurt-based drink, often blended with water, spices, and fruit, such as mango. It is creamy, refreshing, and served chilled, making it a perfect accompaniment to spicy meals.",
  },
  {
    id: 12,
    image: "../chai.webp",
    span1: "Chai:",
    span2: "Your Daily Dose",
    span3: "of Warmth and Tradition",
    Productnu: "Product 11 /  12",
    Productdisc: "Chai is a popular Indian tea made by brewing black tea leaves with spices like cardamom, ginger, and cloves, mixed with milk and sugar. It’s aromatic, flavorful, and often enjoyed as a comforting beverage throughout the day.",
  },
  {
    id: 13,
    image: "../dahi samosa.webp",
    span1: "Dahi Samosa:",
    span2: "A Tangy Twist",
    span3: "on a Classic Snack",
    Productnu: "Product 12 /  12",
    Productdisc: "Dahi Samosa is a delightful Indian snack consisting of crispy samosas topped with yogurt, tangy chutneys, and spices. The combination of crunchy and creamy textures, along with the flavors, makes it a popular street food treat.",
  },
];
export default Productdb;
