import React from 'react'
import AnimatedGallery from './AnimatedGallery'
import Usp from './Usp'
import Vision from './Vision'
const About = () => {
  return (
    <div >
      <Vision/>
      <AnimatedGallery/>      
      <Usp/>
       </div>
  )
}
export default About;
