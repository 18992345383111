const GalleryDb = [
  {
    id: 1,
    image: " ../P1810832.webp",
    category: "Outlet",
  },
  {
    id: 2,
    image: "../PNX01005.webp",
    category: "Outlet",
  },
  {
    id: 3,
    image: "../PNX01003.webp",
    category: "Outlet",
  },
  {
    id: 4,
    image: "../P1810627.webp",
    category: "Outlet",
  },
  {
    id: 5,
    image: "../P1810761.webp",
    category: "Outlet",
  },
  {
    id: 6,
    image: "../IMG_9433.webp",
    category: "Outlet",
  },
  // {
  //   id: 7,
  //   image: "",
  //   category: "Outlet",
  // },
  {
    id: 8,
    image: "../IMG_9429.webp",
    category: "Outlet",
  },
  {
    id: 10,
    image: "../IMG_8050.webp",
    category: "Event",
  },
  // {
  //   id: 11,
  //   image: "",
  //   category: "Event",
  // },

  {
    id: 12,
    image: "../4.webp",
    category: "Event",
  },
  {
    id: 13,
    image: "../5.webp",
    category: "Event",
  },
  {
    id: 14,
    image: "../7.webp",
    category: "Event",
  },
  // {
  //   id: 15,
  //   image: "",
  //   category: "Event",
  // },
  {
    id: 16,
    image: "../14.webp",
    category: "Event",
  },
];

export default GalleryDb;
